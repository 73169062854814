import './header.css';

import headerHTML from './header.html';

import { createHTML } from 'Modules/html.mjs';

export class Header {
	constructor() {
		this.html = createHTML(headerHTML);
	}
}