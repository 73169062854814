import './login.css';

import LoginHTML from './login.html';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { attemptLogin } from 'Modules/session.mjs';

export class LoginPage extends Page {
	constructor() {
		super();

		this.html = createHTML(LoginHTML);

		const accountInput = this.html.querySelector('#account_input');
		const passwordInput = this.html.querySelector('#password_input');
		const loginButton = this.html.querySelector('#login_button');

		let isLoggingIn = false;
		
		loginButton.addEventListener('click', (event) => {
			if (isLoggingIn) return;
			isLoggingIn = true;

			if (!loginButton.classList.contains('disabled')) {
				loginButton.classList.add('disabled');
			}

			attemptLogin(accountInput.value, passwordInput.value).then((response) => {
				console.log('Login response', response);

				navigateTo('/account');
			}).catch((error) => {
				console.warn('Failed to login');
				console.log(error);
			}).finally(() => {
				isLoggingIn = false;

				if (loginButton.classList.contains('disabled')) {
					loginButton.classList.remove('disabled');
				}
			});

			// const payload = btoa(JSON.stringify({ email: accountInput.value, password: passwordInput.value }));

			// fetch(window.location.origin + `/api/account/login`, {
			// 	method: 'POST',
			// 	body: payload,
			// }).then((response) => {
			// 	response.json().then((jsonResponse) => {
			// 		if (!jsonResponse.success) {
			// 			console.warn(`Login failed, ${jsonResponse.message || '?'}`);

			// 			return;
			// 		}

			// 		if (jsonResponse.sessionToken) {
			// 			console.log('Login success');

			// 			localStorage.setItem('session_token', jsonResponse.sessionToken);

			// 			navigateTo('/account');
			// 		} else {
			// 			console.warn(`Login failed, no session token returned`);
			// 		}
			// 	}).finally(enableLoginButton);
			// }).catch((error) => {
			// 	console.log('fetch error');
			// 	console.log(error);
			// }).finally(enableLoginButton);
		});
	}
}