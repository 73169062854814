import './contractors.css';

import ContractorsHTML from './contractors.html';
import ContractorResultHTML from './contractor_result.html';

import { navigateTo } from 'Modules/router.mjs';
import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { Spinner } from 'Classes/spinner/spinner.mjs';

import { availableRoles, formatJobRoles } from 'Data/roles.mjs';
import { availableCounties } from 'Data/counties.mjs';

const firstNames = ["John", "Jane", "Alex", "Emma", "Chris"];
const lastNames = ["Smith", "Doe", "Johnson", "Davis", "Taylor"];

function getRandomValue(array) {
	return array[Math.floor(Math.random() * array.length)];
}

function showRandomContractor() {
	const randomID = Math.floor(1_000_000 * Math.random());

	navigateTo(`/user/${randomID}`);
}

export class ContractorsPage extends Page {
	constructor() {
		super();

		this.html = createHTML(ContractorsHTML);
		this.loadingSpinner = new Spinner();

		// Setup roles
		const roleContainer = this.html.querySelector('#role_input');

		for (let role of availableRoles) {
			const roleSelection = createHTML(`<option value="${role}">${role}</option>`);

			roleContainer.appendChild(roleSelection);
		}

		// Setup counties
		const countyContainer = this.html.querySelector('#county_input');

		for (let county of availableCounties) {
			const countySelection = createHTML(`<option value="${county}">${county}</option>`);

			countyContainer.appendChild(countySelection);
		}

		// Search button
		this.html.querySelector('#search_contractors_button').addEventListener('click', (event) => {
			const url = new URL(window.location);

			if (!roleContainer.value || roleContainer.value == '') {
				url.searchParams.delete('roles');
			} else {
				url.searchParams.set('roles', roleContainer.value);
			}

			if (!countyContainer.value || countyContainer.value == '') {
				url.searchParams.delete('county');
			} else {
				url.searchParams.set('county', countyContainer.value);
			}

			window.history.pushState({}, '', url);

			this.loadContractors();
		});

		// Last contractor
		this.html.querySelector('#view_last_contractor').addEventListener('click', (event) => {

		});

		// Next
		this.html.querySelector('#view_next_contractor').addEventListener('click', (event) => {

		});
	}

	open() {
		// this.loadContractors();
	}

	async loadContractors() {

		// Validate query
		const pathQuery = new URLSearchParams(window.location.search);
		const roles = pathQuery.get('roles');
		const county = pathQuery.get('county');
		const contractorID = pathQuery.get('id');

		if (!roles && !county) {
			console.log('Not loading anything, no query');

			return;
		}

		// Show spinner
		const contractorsResultsDiv = this.html.querySelector('.contractors_results');

		if (!contractorsResultsDiv.classList.contains('hidden')) {
			contractorsResultsDiv.classList.add('hidden');
		}

		this.loadingSpinner.setParent(this.html);

		// Build query
		let query;

		if (roles) {
			query = (query ? query + '&' : '?') + `roles=${roles}`;
		}

		if (county) {
			query = (query ? query + '&' : '?') + `county=${county}`;
		}

		// if (contractorID) {
		// 	query = (query ? query + '&' : '?') + `lastID=${contractorID}`;
		// }

		// Get contractors
		return fetch(`/api/contractors/getNext` + (query || ''), { method: 'GET' }).then((response) => {
			response.json().then((jsonResponse) => {
				if (!jsonResponse) return;

				if (jsonResponse.success && jsonResponse.contractorData) {
					this.setContractorData(jsonResponse.contractorData);
				}
			}).catch((error) => {
				console.warn('Failed to decode json');
				console.log(error);
			});
		}).finally(() => {

			// Hide spinner
			this.loadingSpinner.setParent();
		});
	}

	setContractorData(contractorData) {
		this.contractorData = contractorData;

		// Update labels
		const contractorsResultsDiv = this.html.querySelector('.contractors_results');

		if (contractorData) {
			this.html.querySelector('#contractor_name').innerHTML = contractorData.firstName + ' ' + contractorData.lastName;
			this.html.querySelector('#contractor_roles').innerHTML = formatJobRoles(contractorData.roles);

			if (contractorsResultsDiv.classList.contains('hidden')) {
				contractorsResultsDiv.classList.remove('hidden');
			}
		} else {
			if (!contractorsResultsDiv.classList.contains('hidden')) {
				contractorsResultsDiv.classList.add('hidden');
			}
		}

		// Update url
		const url = new URL(window.location);

		if (contractorData && contractorData.id) {
			url.searchParams.set('id', contractorData.id);
		} else {
			url.searchParams.delete('id');
		}

		window.history.pushState({}, '', url);

		console.log('Set contractorData to', contractorData);
	}
}