let factory = document.createElement("div");
factory.style.display = 'none';

export function createHTML(raw_html) {
	if (!factory.parentNode) {
		document.body.appendChild(factory);
	}

	factory.innerHTML = raw_html;

	if (factory.childElementCount > 1) {
		var children_source = factory.children;
		var children = [];

		for (var i = 0; i < children_source.length; i++) {
			children.push(children_source[i]);
		}

		while (factory.lastElementChild) {
			factory.lastElementChild.remove();
		}

		factory.remove()

		return children;
	} else {
		var html = factory.firstChild;
		html.remove();
		factory.remove();

		return html;
	}
}