export const availableRoles = [
	"All roles",
	"Stonemason", "Plumber", "Electrician", "Carpenter", "Painter", "Roofer", "Builder", "Plasterer", 
	"HVAC Technician", "Bricklayer", "Landscaper", "Flooring Specialist", "General Contractor", "Welder", "Tiler", "Blacksmith"
];

export function formatJobRoles(jobRoles) {
	if (!jobRoles) return 'No roles';

	return jobRoles.map(str => str.charAt(0).toUpperCase() + str.slice(1)).join(', ');
}