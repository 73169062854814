import './news.css';

import NewsHTML from './news.html';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { Spinner } from 'Classes/spinner/spinner.mjs';

const postTimeFormat = {
	weekday: 'long',
	year: 'numeric',
	month: 'long',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
}

export class NewsPage extends Page {
	constructor() {
		super();

		this.html = createHTML(NewsHTML);
		this.isDynamicPage = true;

		this.loadingSpinner = new Spinner();
		this.articlePanel = this.html.querySelector('.article_panel');
	}

	open() {

		// Hide panel
		if (!this.articlePanel.classList.contains('hidden')) {
			this.articlePanel.classList.add('hidden');
		}

		// Show spinner
		this.loadingSpinner.setParent(this.html);

		// Load article
		const pathSegments = window.location.pathname.split('/');
		const articleID = pathSegments[2];

		fetch(window.location.origin + '/api/news/getArticle?aid=' + articleID, {
			method: 'GET',
		}).then((response) => {
			response.json().then((jsonResponse) => {
				if (!jsonResponse) return;

				if (jsonResponse.success && jsonResponse.data) {
					// this.setArticleData(jsonResponse.data);

					setTimeout(() => {
						this.setArticleData(jsonResponse.data);
					}, 500);
				}
			}).catch((error) => {
				console.log('failed to decode json');
				console.log(error);
			});
		});
	}

	setArticleData(articleData) {
		this.articleData = articleData;

		if (articleData) {
			const postTime = articleData.postTime ? new Date(articleData.postTime * 1000) : null;

			this.html.querySelector('#article_title').innerHTML = articleData.title || '?';
			this.html.querySelector('#article_post_time').innerHTML = postTime ? postTime.toLocaleDateString('en-IE', postTimeFormat) : '?';
			this.html.querySelector('#article_content').innerHTML = articleData.content || '?';

			// Hide spinner
			this.loadingSpinner.setParent();

			// Show panel
			if (this.articlePanel.classList.contains('hidden')) {
				this.articlePanel.classList.remove('hidden');
			}
		} else {

			// Show panel
			if (!this.articlePanel.classList.contains('hidden')) {
				this.articlePanel.classList.add('hidden');
			}

			// Show spinner
			this.loadingSpinner.setParent(this.html);
		}
	}
}