import './jobs.css';

import JobsHTML from './jobs.html'
import JobItemHTML from './job_item.html';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { Spinner } from 'Classes/spinner/spinner.mjs';

import { availableRoles, formatJobRoles } from 'Data/roles.mjs';
import { availableCounties } from 'Data/counties.mjs';
import { navigateTo } from 'Modules/router.mjs';
import { getJobFromID } from 'Modules/job_manager.mjs';
import { getPage } from 'Pages/page_index.mjs';

import { JobsNewPage } from './new/new.mjs';
import { JobsOffersPage } from './offers/offers.mjs';
import { JobsOfferedPage } from './offered/offered.mjs';

export class JobsPage extends Page {
	constructor() {
		super();

		this.html = createHTML(JobsHTML);

		this.noJobsLabel = this.html.querySelector('#no_jobs_label');
		this.noJobSelectedLabel = this.html.querySelector('#no_job_selected_label');

		this.loadingSpinner = new Spinner();
		this.selectedJobSpinner = new Spinner();

		this.isDynamicPage = true;
		this.childPages = {
			'new': new JobsNewPage(),
			'offers': new JobsOffersPage(),
			'offered': new JobsOfferedPage(),
		}

		// Setup roles
		const roleContainer = this.html.querySelector('#role_input');

		for (let role of availableRoles) {
			const roleSelection = createHTML(`<option value="${role}">${role}</option>`);

			roleContainer.appendChild(roleSelection);
		}

		// Setup counties
		const countyContainer = this.html.querySelector('#county_input');

		for (let county of availableCounties) {
			const countySelection = createHTML(`<option value="${county}">${county}</option>`);

			countyContainer.appendChild(countySelection);
		}

		// Search button
		this.html.querySelector('#jobs_filter_search').addEventListener('click', (event) => {
			const url = new URL(window.location);

			if (!roleContainer.value || roleContainer.value == '') {
				url.searchParams.delete('roles');
			} else {
				url.searchParams.set('roles', roleContainer.value);
			}

			if (!countyContainer.value || countyContainer.value == '') {
				url.searchParams.delete('county');
			} else {
				url.searchParams.set('county', countyContainer.value);
			}

			window.history.pushState({}, '', url);

			this.loadJobs();
		});
	}

	open() {
		this.isOpen = true;

		// Update filter from query
		const pathQuery = new URLSearchParams(window.location.search);
		const roles = pathQuery.get('roles');
		const county = pathQuery.get('county');

		if (roles) {
			this.html.querySelector('#role_input').value = roles;
		}

		if (county) {
			this.html.querySelector('#county_input').value = county;
		}

		// Load jobs
		this.loadJobs();
		this.updateViewingJob();

		// Make job sticky
		const jobsPage = this;
		const contentRight = this.html.querySelector('.work_content_right');

		function updateSticky() {
			if (!jobsPage.isOpen) return;

			const bounds = contentRight.getBoundingClientRect();
			
			contentRight.style.paddingTop = Math.abs(Math.min(bounds.y - 16, 0)) + 'px';

			requestAnimationFrame(updateSticky)
		}

		requestAnimationFrame(updateSticky);
	}

	close() {
		this.isOpen = false;
	}

	updateViewingJob() {

		// Show spinner
		this.noJobSelectedLabel.remove();
		getPage('job').setParent();

		this.selectedJobSpinner.setParent(this.html.querySelector('.work_content_right'));

		// Get job ID from url
		const pathQuery = new URLSearchParams(window.location.search);
		const jobID = pathQuery.get('jid');

		if (jobID) {

			// Get job data
			getJobFromID(jobID).then((jobData) => {
				// this.setJobData(jobData);

				setTimeout(() => {
					this.setJobData(jobData);
				}, 200);
			}).catch((error) => {
				console.log('failed to get job');
				console.log(error);
			});
		} else {
			this.setJobData();
		}
	}

	setJobData(jobData) {
		this.jobData = jobData;

		// Hide spinner
		this.selectedJobSpinner.setParent();

		// Update page
		const jobPage = getPage('job');
		const container = this.html.querySelector('.work_content_right');

		if (jobData) {

			// Show page
			jobPage.setJobData(jobData);
			jobPage.setParent(container);
		} else {

			// Hide page
			jobPage.setParent();

			// Show no selection
			container.appendChild(this.noJobSelectedLabel);
		}
	}

	async loadJobs() {

		// Hide current job items
		if (this.jobResultItems) {
			for (let item of this.jobResultItems) {
				item.remove();
			}
		}

		// Show spinner
		this.noJobsLabel.remove();
		this.loadingSpinner.setParent(this.html.querySelector('.work_content_left'));

		// Get jobs to load from path query
		const pathQuery = new URLSearchParams(window.location.search);
		const roles = pathQuery.get('roles');
		const county = pathQuery.get('county');

		let query;

		if (roles) {
			query = (query ? query + '&' : '?') + `roles=${roles}`;
		}

		if (county) {
			query = (query ? query + '&' : '?') + `county=${county}`;
		}
		
		// Fetch jobs
		return fetch(`/api/jobs/getJobs` + (query || ''), { method: 'GET' }).then((response) => {
			response.json().then((jsonResponse) => {
				if (!jsonResponse) return;

				if (jsonResponse.success && jsonResponse.jobs) {
					setTimeout(() => {
						this.setJobs(jsonResponse.jobs);
					}, 500)
				}
			}).catch((error) => {
				console.log('failed to decode json');
				console.log(error);
			});
		});
	}

	setJobs(jobs) {

		// Hide spinner
		this.loadingSpinner.setParent();

		// Get containers
		const jobsPage = this;
		const container_left = this.html.querySelector('.work_content_left');
		const container_right = this.html.querySelector('.work_content_right');

		if (!this.jobResultItems) {
			this.jobResultItems = [];
		}

		// Create more job items if not enough
		if (jobs && this.jobResultItems.length < jobs.length) {
			for (let key = this.jobResultItems.length; key < jobs.length; key++) {
				const jobResultItem = createHTML(JobItemHTML);
				this.jobResultItems.push(jobResultItem);

				function viewJob() {
					const jobID = jobResultItem.getAttribute('jobID');
					const checkStyle = window.getComputedStyle(container_right);

					if (checkStyle && checkStyle.display == 'none') {
						navigateTo(`/job/${jobID}`);
					} else {
						const url = new URL(window.location);
						url.searchParams.set('jid', jobID);
						window.history.pushState({}, '', url);

						jobsPage.updateViewingJob();
					}
				}

				jobResultItem.querySelector('.work_result_item_title').addEventListener('click', viewJob);
				jobResultItem.querySelector('.view_job').addEventListener('click', viewJob)
			}
		}

		// Update job items
		let visibleJobs = 0;

		for (let key in this.jobResultItems) {
			let jobResultItem = this.jobResultItems[key];
			let jobData = jobs && jobs[key] || null;

			if (jobData) {
				jobResultItem.setAttribute('jobID', jobData.jobID);

				const jobRoles = jobData && jobData.roles && formatJobRoles(jobData.roles) || null;

				jobResultItem.querySelector('.work_result_item_title').innerHTML = jobData.title || '?';
				jobResultItem.querySelector('.work_result_item_roles').innerHTML = jobRoles || '?';
				jobResultItem.querySelector('.work_result_item_desc').innerHTML = jobData.description || '?';

				container_left.appendChild(jobResultItem);

				visibleJobs += 1;
			} else {
				jobResultItem.remove();
			}
		}

		// Show no jobs available label
		if (visibleJobs > 0) {
			this.noJobsLabel.remove();
		} else {
			container_left.appendChild(this.noJobsLabel);
		}
	}
}