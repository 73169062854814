import './user.css';

import UserHTML from './user.html';

import { createHTML } from 'Modules/html.mjs';
import { navigateTo } from 'Modules/router.mjs';
import { promptLogin } from 'Modules/session.mjs';

export class UserComponent {
	constructor(itemList) {
		this.itemList = itemList;
		this.html = createHTML(UserHTML);

		// Bookmark button
		const bookmarkButton = this.html.querySelector('#bookmark_button');

		let isBookmarking = false;

		bookmarkButton.addEventListener('click', (event) => {

			// Ignore if busy
			if (isBookmarking) return;

			// Prompt if not logged in
			if (promptLogin({ text: 'Log in to bookmark this contractor' })) {
				return;
			}

			// Validate job ID
			const itemData = this.itemList.viewingItemData;
			const userID = itemData && itemData.id || null;

			if (!userID) {
				console.warn(`Failed to bookmark, ID not found`);

				return
			}

			// Disable button
			isBookmarking = true;

			if (!bookmarkButton.classList.contains('disabled')) {
				bookmarkButton.classList.add('disabled');
			}

			// Request add
			fetch(window.location.origin + `/api/bookmarks/add`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					auth: localStorage.getItem('session_token'),

					category: 'contractors',
					referenceID: userID,
				}),
			}).then((response) => {
				response.json().then((jsonResponse) => {
					if (!jsonResponse) return;

					console.log(jsonResponse);
				}).catch((error) => {
					console.warn('Failed to decode json');
					console.log(error);
				});
			}).finally(() => {

				// Enable button
				if (bookmarkButton.classList.contains('disabled')) {
					bookmarkButton.classList.remove('disabled');
				}

				isBookmarking = false;
			});
		})

		// View button
		this.html.querySelector('#view_button').addEventListener('click', (event) => {
			const itemData = this.itemList.viewingItemData;

			if (itemData && itemData.id) {
				navigateTo(`/u/${itemData.id}`);
			} else {
				console.warn(`Failed to view user, ID not found`);
			}
		})
	}
}