import './offered.css';

import JobsOfferedHTML from './offered.html'

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';

export class JobsOfferedPage extends Page {
	constructor() {
		super();

		this.html = createHTML(JobsOfferedHTML);
	}
}