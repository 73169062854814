import './dropdown_test.css';

import DropdownTestHTML from './dropdown_test.html';
import DropdownButtonHTML from './dropdown_button.html';

import { createHTML } from 'Modules/html.mjs';

export class DropdownTest {
	constructor() {
		this.html = createHTML(DropdownTestHTML);
		this.noOptionsLabel = this.html.querySelector('.dropdown_none_label');

		window.addEventListener('scroll', () => {
			if (this.parentDiv) {
				this.moveToParentDiv();
			}
		});
	}

	open(parentDiv, selectionCallback, options) {
		this.options = options;
		this.selectionCallback = selectionCallback;

		this.updateButtons();
		this.setParent(parentDiv);
	}

	updateButtons() {
		const options = this.options;

		if (!this.optionButtons) {
			this.optionButtons = [];
		}

		// Create more option buttons if not enough
		if (options && this.optionButtons.length < options.length) {
			for (let key = this.optionButtons.length; key < options.length; key++) {
				const button = createHTML(DropdownButtonHTML);

				// Fire callback on click
				button.addEventListener('mousedown', (event) => {
					if (this.selectionCallback) {
						this.selectionCallback(this.options[key]);
					}
				});

				this.optionButtons.push(button);
			}
		}

		// Update option buttons
		const container = this.html.querySelector('.dropdown_options');

		let visibleButtons = 0;

		for (let key in this.optionButtons) {
			const button = this.optionButtons[key];
			
			let optionData = options && options[key] || null;

			if (optionData && this.filterText && !optionData.toLowerCase().includes(this.filterText)) {
				optionData = null;
			}

			if (optionData) {
				button.innerHTML = optionData;

				container.appendChild(button);

				visibleButtons += 1;
			} else {
				button.remove();
			}
		}
		
		if (visibleButtons > 0) {
			if (!this.noOptionsLabel.classList.contains('hidden')) {
				this.noOptionsLabel.classList.add('hidden')
			}
		} else {
			if (this.noOptionsLabel.classList.contains('hidden')) {
				this.noOptionsLabel.classList.remove('hidden')
			}
		}
	}

	setParent(parentDiv) {
		if (this.parentDiv == parentDiv) return;
		this.parentDiv = parentDiv;

		if (parentDiv) {
			this.moveToParentDiv();

			document.body.appendChild(this.html);
		} else {
			this.html.remove();
		}
	}

	clearParent(parentDiv) {
		if (this.parentDiv == parentDiv) {
			this.setParent();
		}
	}

	moveToParentDiv() {
		if (!this.parentDiv) return;

		const rect = this.parentDiv.parentElement.getBoundingClientRect();

		this.html.style.top = (rect.top + rect.height) + 'px';
		this.html.style.left = (rect.left + 8) + 'px';
		this.html.style.width = (rect.width - 16) + 'px';
		this.html.querySelector('.dropdown_options').style['max-height'] = (window.innerHeight - rect.bottom - 40) + 'px';
	}

	setFilterText(filterText) {
		this.filterText = filterText.toLowerCase();

		this.updateButtons();
	}
}