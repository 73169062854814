import './reviews.css';

import ReviewsHTML from './reviews.html'

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';

export class UserReviewsPage extends Page {
	constructor() {
		super();
		
		this.html = createHTML(ReviewsHTML);
	}
}