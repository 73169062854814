import './itemlist.css';

import ItemListHTML from './itemlist.html'
import ItemListItemHTML from './item.html';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { Spinner } from 'Classes/spinner/spinner.mjs';
import { UserComponent } from './user.mjs';

import { availableRoles, formatJobRoles } from 'Data/roles.mjs';
import { availableCounties } from 'Data/counties.mjs';
import { navigateTo } from 'Modules/router.mjs';
import { getPage } from 'Pages/page_index.mjs';
import { setHasClass, setDropdownItems } from 'Modules/util.mjs';

import { Buffer } from 'buffer';

const validCategoryNames = ['jobs', 'contractors'];

function setupItemButton(page, key, button) {
	button.setAttribute('key', key);
	button.addEventListener('click', (event) => {
		page.openItemOnClick(event);
	});
}

export class ItemListPage extends Page {
	constructor() {
		super();

		this.html = createHTML(ItemListHTML);
		this.noItemsLabel = this.html.querySelector('#no_items_label');
		this.noItemSelectedLabel = this.html.querySelector('#no_item_selected_label');

		this.leftSpinner = new Spinner();
		this.rightSpinner = new Spinner();

		this.isDynamicPage = true;

		// Search button
		const primaryFilterInput = this.html.querySelector('#item_filter_primary_input');
		const secondaryFilterInput = this.html.querySelector('#item_filter_secondary_input');

		this.html.querySelector('#item_filter_search').addEventListener('click', (event) => {

			// Update filter
			const url = new URL(window.location);

			url.searchParams.set('pf', primaryFilterInput.value || null);
			url.searchParams.set('sf', secondaryFilterInput.value || null);

			window.history.pushState({}, '', url);

			// Fetch
			this.fetchItems().catch((error) => {
				console.warn('Failed to fetch items');
				console.log(error);
			}).finally(() => {
				console.log('fetched')
			});
		});
	}

	open() {
		this.isOpen = true;

		this.refresh();

		// Make sticky
		const page = this;
		const contentRight = this.html.querySelector('.item_list_content_right');

		function updateSticky() {
			if (!page.isOpen) return;

			const bounds = contentRight.getBoundingClientRect();

			// contentRight.style.paddingTop = Math.abs(Math.min(bounds.y - 16, 0)) + 'px';

			requestAnimationFrame(updateSticky)
		}

		requestAnimationFrame(updateSticky);
	}

	afterOpened() {
		
	}

	close() {
		this.lastScrollY = window.scrollY;
		this.isOpen = false;
	}

	refresh() {
		
		// Update filter items
		this.setViewingItem();

		// Get items
		this.fetchItems().catch((error) => {
			console.warn('Failed to fetch items');
			console.log(error);
		});
	}

	fetchItems() {
		return new Promise(async (resolve, reject) => {

			// Get search data
			const pathQuery = new URLSearchParams(window.location.search);
			const openOnID = pathQuery.get('id');

			let encodedSearchData = pathQuery.get('sd') || pathQuery.get('searchData');
			let decodedSearchData;

			if (encodedSearchData) {
				try {
					decodedSearchData = Buffer.from(encodedSearchData, 'base64').toString('utf-8');
				} catch {
					return reject(`Failed to decode search data`)
				}
			} else {
				let category = pathQuery.get('c') || pathQuery.get('category');
				// let category = pathQuery.get('c') || pathQuery.get('category');
				// let category = pathQuery.get('c') || pathQuery.get('category');

				decodedSearchData = {
					category: category,
				}

				encodedSearchData = Buffer.from(JSON.stringify(decodedSearchData)).toString('base64');
			}

			// Validate category
			const category = decodedSearchData && decodedSearchData.category || null;

			if (!category || !validCategoryNames.includes(category)) {
				console.warn(`Failed to show item list, invalid category`);

				navigateTo('/');

				return;
			}

			this.setCategory(category);

			// Show spinner
			setHasClass(this.html.querySelector('.item_list_items_container'), 'hidden', true);

			this.noItemsLabel.remove();
			this.leftSpinner.setParent(this.html.querySelector('.item_list_content_left'));

			// Fetch items
			return fetch(`/api/items/get?sd=${encodedSearchData}`, {}).then((response) => {
				return response.json().then((jsonResponse) => {
					if (jsonResponse.success && jsonResponse.items) {
						this.leftSpinner.setParent();

						this.setItems(jsonResponse.items, decodedSearchData);

						if (openOnID) {
							for (let itemData of jsonResponse.items) {
								if (itemData.id == openOnID) {
									this.setViewingItem(itemData);
								}
							}
						}

						resolve(jsonResponse.items, decodedSearchData);
					}
				}).catch(reject)
			}).catch(reject).finally(() => {

				// Hide spinner
				this.leftSpinner.setParent();
			});
		});
	}

	setItems(items, searchData) {
		this.items = items;
		this.searchData = searchData;

		if (!this.itemDivs) {
			this.itemDivs = [];
		}

		// Create more item divs if not enough
		if (items && this.itemDivs.length < items.length) {
			for (let key = this.itemDivs.length; key < items.length; key++) {
				const itemDiv = createHTML(ItemListItemHTML);

				setupItemButton(this, key, itemDiv.querySelector('.item_list_item_title'));
				setupItemButton(this, key, itemDiv.querySelector('#view_button'));

				this.itemDivs.push(itemDiv);
			}
		}

		// Update item divs
		const itemDivContainer = this.html.querySelector('.item_list_items_container');

		let visibleItems = 0;

		for (let key in this.itemDivs) {
			const itemDiv = this.itemDivs[key];
			const itemData = items ? items[key] : null;

			if (itemData) {

				// Basic label updates
				itemDiv.querySelector('.item_list_item_title').innerHTML = itemData.title || '?';
				itemDiv.querySelector('.item_list_item_subtitle').innerHTML = itemData.subtitle || '?';

				// Update description
				const descriptionLabel = itemDiv.querySelector('.item_list_item_description');

				if (itemData.description) {
					descriptionLabel.innerHTML = itemData.description || '?';

					if (descriptionLabel.classList.contains('hidden')) {
						descriptionLabel.classList.remove('hidden')
					}
				} else {
					if (!descriptionLabel.classList.contains('hidden')) {
						descriptionLabel.classList.add('hidden')
					}
				}

				itemDivContainer.appendChild(itemDiv);

				visibleItems += 1;
			} else {
				itemDiv.remove();
			}
		}

		if (visibleItems > 0) {
			this.noItemsLabel.remove();
		} else {
			itemDivContainer.appendChild(this.noItemsLabel);
		}

		setHasClass(this.html.querySelector('.item_list_items_container'), 'hidden', visibleItems <= 0);
	}

	setCategory(category) {
		this.category = category;

		// Labels
		this.html.querySelector('.panel_title').innerHTML = (category || '?').toUpperCase();
		this.html.querySelector('#view_bookmarks_button').setAttribute('href', `/b?c=${category}`);

		// Filter
		let primaryFilterItems;
		let secondaryFilterItems;

		if (category == 'jobs' || category == 'contractors') {
			this.html.querySelector('#item_filter_primary_label').innerHTML = 'Role';
			this.html.querySelector('#item_filter_secondary_label').innerHTML = 'County';

			setDropdownItems(this.html.querySelector('#item_filter_primary_input'), availableRoles);
			setDropdownItems(this.html.querySelector('#item_filter_secondary_input'), availableCounties);
		} else {
			console.warn(`Unhandled category ${category}`);
		}
	}

	openItemOnClick(event) {

		// Validate item data
		const key = event.target.getAttribute('key');
		const itemData = key && this.items && this.items[key] || null;

		if (!itemData) {
			console.warn(`Failed to open item, invalid item data`);

			return;
		}

		// Open it
		this.openItem(itemData);
	}

	// Fires whenever the user wants to view this job
	openItem(itemData) {
		this.activeItemData = itemData;

		// Handle category
		const category = this.searchData && this.searchData.category || null;

		if (!category) {
			console.warn(`Failed to open item, invalid category`);

			return;
		}

		// Open
		if (category == 'jobs') {

			// navigateTo(`/job/${itemData.id}`);
			this.setViewingItem(itemData);
		} else if (category == 'contractors') {

			// navigateTo(`/user/${itemData.id}`);
			this.setViewingItem(itemData);
		} else {
			console.warn(`Unhandled category ${category} when opening item`);
		}
	}

	// Puts this item on the right column
	setViewingItem(itemData) {
		this.viewingItemData = itemData;

		// Handle category
		const container = this.html.querySelector('.item_list_content_right');
		const category = itemData && this.searchData && this.searchData.category || null;
		const jobComponent = getPage('job');

		let userComponent = this.userComponent;

		// Update no selection label
		if (category) {
			this.noItemSelectedLabel.remove();
		} else {
			container.appendChild(this.noItemSelectedLabel);
		}

		// Close job page
		jobComponent.setParent();

		// if (category !== 'jobs') {
		// 	jobComponent.setParent();
		// }

		// Close user page
		if (userComponent) {
			userComponent.html.remove();
		}

		// if (userComponent && category !== 'contractors') {
		// 	userComponent.html.remove();
		// }

		// Open pages
		if (itemData) {
			const checkStyle = window.getComputedStyle(container);
			const isRightContainerVisible = checkStyle && checkStyle.display != 'none';

			if (category == 'jobs') {
				if (isRightContainerVisible) {

					// Show page in right container
					jobComponent.setJobData(itemData);
					// jobComponent.setParent();

					setTimeout(() => {
						jobComponent.setParent(container);
					}, 50);

					// Update URL
					if (itemData.id) {
						const url = new URL(window.location);
						url.searchParams.set('id', itemData.id);
						window.history.pushState({}, '', url);
					}
				} else {

					// Navigate to job page
					navigateTo(`/job/${itemData.id}`);
				}
			} else if (category == 'contractors') {
				if (isRightContainerVisible) {

					// Create user component
					if (!this.userComponent) {
						this.userComponent = new UserComponent(this);
					}

					// Update user component
					this.userComponent.html.querySelector('.user_component_title').innerHTML = itemData.title || '?';
					this.userComponent.html.querySelector('.user_component_subtitle').innerHTML = itemData.subtitle || '?';
					this.userComponent.html.querySelector('.user_component_description_content').innerHTML = itemData.description || 'No description given';

					// Show user in right container
					// container.appendChild(this.userComponent.html);
					setTimeout(() => {
						container.appendChild(this.userComponent.html);
					}, 50);

					// Update URL
					if (itemData.id) {
						const url = new URL(window.location);
						url.searchParams.set('id', itemData.id);
						window.history.pushState({}, '', url);
					}
				} else {

					// Navigate to user page
					navigateTo(`/user/${itemData.id}`);
				}
			} else {
				console.warn(`Unhandled category ${category} when attempting to view item`);
			}
		}
	}
}