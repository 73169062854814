import './offers.css';

import JobsOffersHTML from './offers.html'

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';

export class JobsOffersPage extends Page {
	constructor() {
		super();

		this.html = createHTML(JobsOffersHTML);
	}
}