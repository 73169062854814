export class Modal {
	open() {
		// console.warn(`Open method not implemented for this modal`);

		// Open base HTML
		if (this.baseHTML) {
			if (this.html) {
				this.baseHTML.appendChild(this.html);
			} else {
				console.warn('Failed to open html, not found');
			}
		} else {
			console.warn(`Failed to open base HTML, not found`);
		}
	}

	close() {
		// console.warn(`Close method not implemented for this modal`);

		if (this.html) {
			this.html.remove();
		}
	}
}