import './new.css';

import JobsNewHTML from './new.html'
import SelectionItemHTML from './selection_item.html';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { navigateTo } from 'Modules/router.mjs';
import { DropdownTest } from './dropdown_test.mjs';
import { availableRoles } from 'Data/roles.mjs';

export class JobsNewPage extends Page {
	constructor() {
		super();

		this.html = createHTML(JobsNewHTML);

		// Role input
		const roleInput = this.html.querySelector('#role_input');
		const dropdownTest = new DropdownTest();

		function addSelectedRole(role) {
			roleInput.value = '';

			// Ignore if already in selection
			for (let child of roleInput.parentElement.children) {
				if (child.getAttribute('role') === role) return;
			}

			// Add role to selection
			const div = createHTML(SelectionItemHTML);
			div.setAttribute('role', role);
			div.innerHTML = role;

			div.addEventListener('click', () => {
				div.remove();
			});

			roleInput.parentElement.insertBefore(div, roleInput);
		}

		roleInput.addEventListener('focus', (event) => {
			dropdownTest.open(roleInput, addSelectedRole, availableRoles);
		});

		roleInput.addEventListener('input', (event) => {
			dropdownTest.setFilterText(roleInput.value);
		});
		
		roleInput.addEventListener('blur', (event) => {
			dropdownTest.clearParent(roleInput);
		});

		// Preview button
		this.html.querySelector('.preview_button').addEventListener('click', () => {

			// Format job data
			const jobData = {
				title: this.html.querySelector('#title_input').value,
				roles: [],
				location: this.html.querySelector('#location_input').value,
				description: this.html.querySelector('#description_input').value,
				pay: this.html.querySelector('#pay_input').value,
				deadline: this.html.querySelector('#deadline_input').value,
				postTime: new Date().getTime() / 1000,
			};

			// Add roles
			for (let child of roleInput.parentElement.children) {
				const role = child.getAttribute('role');

				if (role) {
					jobData.roles.push(role);
				}
			}

			// Go to job preview
			const encodedJobData = btoa(JSON.stringify(jobData));

			navigateTo(`/job?pjd=${encodedJobData}`);
		});
	}
}