import './apply.css';

import ApplyHTML from './apply.html'

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';

export class JobApplyPage extends Page {
	constructor() {
		super();
		
		this.html = createHTML(ApplyHTML);
	}
}