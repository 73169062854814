import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
	apiKey: "AIzaSyCK9DTFB2MlK_zPbZbA0hj5supjrJx08Hg",
	authDomain: "obair-9138f.firebaseapp.com",
	projectId: "obair-9138f",
	storageBucket: "obair-9138f.firebasestorage.app",
	appId: "1:945619612237:web:2778dc98cee2b589cae204"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

// Connect emulators
const hostname = window.location.hostname;

if (hostname === 'localhost' || hostname === '127.0.0.1') {
	console.log(`Connecting to firebase emulators`);

	connectFunctionsEmulator(functions, '127.0.0.1', 5001);
}