import './gallery.css';

import GalleryHTML from './gallery.html'

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';

export class UserGalleryPage extends Page {
	constructor() {
		super();
		
		this.html = createHTML(GalleryHTML);
	}
}