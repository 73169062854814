import './pay.css';

import PayModalHTML from './pay.html';

import { createHTML } from 'Modules/html.mjs';
import { Modal } from "Classes/modal/modal.mjs";

export class PayModal extends Modal {
	constructor() {
		super();

		this.html = createHTML(PayModalHTML);
	}
	// open() {
	// 	console.log('open pay prompt');
	// }
}