import './spinner.css';

import SpinnerHTML from './spinner.html';

import { createHTML } from 'Modules/html.mjs';

export class Spinner {
	constructor() {
		this.html = createHTML(SpinnerHTML);
	}

	setParent(parent) {
		if (parent) {
			parent.appendChild(this.html);
		} else {
			this.html.remove();
		}
	}
}