import './signup.css';

import SignupHTML from './signup.html';

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';
import { navigateTo } from 'Modules/router.mjs';

export class SignupPage extends Page {
	constructor() {
		super();

		this.html = createHTML(SignupHTML);

		// Signup button
		const firstNameInput = this.html.querySelector('#first_name_input');
		const lastNameInput = this.html.querySelector('#last_name_input');
		const emailInput = this.html.querySelector('#email_input');
		const phoneInput = this.html.querySelector('#phone_input');
		const passwordInput = this.html.querySelector('#password_input');
		const confirmPasswordInput = this.html.querySelector('#confirm_password_input');
		const signupButton = this.html.querySelector('#signup_button');

		let isSigningUp = false;

		function enableSignupButton() {
			isSigningUp = false;
			
			if (signupButton.classList.contains('disabled')) {
				signupButton.classList.remove('disabled');
			}
		}

		signupButton.addEventListener('click', (event) => {
			if (isSigningUp) return;
			isSigningUp = true;

			if (!signupButton.classList.contains('disabled')) {
				signupButton.classList.add('disabled');
			}

			// Validate passwords match
			if (confirmPasswordInput.value !== passwordInput.value) {
				console.warn(`Put in the same password you fucking cock sucking slut`);

				return;
			}

			// Format user data
			const userData = {
				firstName: firstNameInput.value,
				lastName: lastNameInput.value,
				email: emailInput.value,
				phone: phoneInput.value,
				password: passwordInput.value,
			}

			// Format payload
			const payload = btoa(JSON.stringify(userData));

			// Send signup request
			fetch(window.location.origin + `/api/account/signup`, {
				method: 'POST',
				body: payload,
			}).then(async (response) => {
				response.json().then((jsonResponse) => {
					if (!jsonResponse.success) {
						console.warn(`Signup failed, ${jsonResponse.message || '?'}`);

						return;
					}

					if (jsonResponse.sessionToken) {
						console.log('Signup success');

						localStorage.setItem('session_token', jsonResponse.sessionToken);

						navigateTo('/account');
					} else {
						console.warn(`Signup failed, no session token returned`);
					}
				}).catch((error) => {
					console.log('failed to decode response json');
					console.log(response.body);
					console.log(error);
				}).finally(enableSignupButton);
			}).catch((error) => {
				console.log('fetch error');
				console.log(error);
			}).finally(enableSignupButton);
		});
	}

	open() {
		const sessionToken = localStorage.getItem('session_token');

		if (sessionToken) {
			navigateTo('/account', true);

			return false;
		}
	}
}