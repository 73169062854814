import { createHTML } from 'Modules/html.mjs';

export function setHasClass(div, className, hasClass) {
	if (hasClass) {
		if (!div.classList.contains(className)) {
			div.classList.add(className);
		}
	} else {
		if (div.classList.contains(className)) {
			div.classList.remove(className);
		}
	}
}

export function setDropdownItems(dropdown, items) {
	if (items && dropdown.children.length < items.length) {
		for (let key = dropdown.children.length; key < items.length; key++) {
			const option = createHTML(`<option>#OPTION</option>`);
			
			dropdown.appendChild(option);
		}
	}

	for (let key = 0; key < dropdown.children.length; key++) {
		const option = dropdown.children[key];
		const itemData = items && items[key] || null;

		if (itemData) {
			option.innerHTML = itemData;
			option.setAttribute('value', itemData);
		} else {
			option.remove();
		}
	}
}