import './pay.css';

import PayHTML from './pay.html'

import { createHTML } from 'Modules/html.mjs';
import { Page } from 'Classes/page/page.mjs';

export class UserPayPage extends Page {
	constructor() {
		super();
		
		this.html = createHTML(PayHTML);
	}
}