import './home.css';

import HomeHTML from './home.html'
import NewsButtonHTML from './news_button.html';

import { createHTML } from 'Modules/html.mjs';
import { navigateTo } from 'Modules/router.mjs';
import { Page } from 'Classes/page/page.mjs';
import { Spinner } from 'Classes/spinner/spinner.mjs';

export class HomePage extends Page {
	constructor() {
		super();

		this.html = createHTML(HomeHTML);

		this.newsSpinner = new Spinner();
		this.newsSpinner.setParent(this.html.querySelector('#home_news_panel'));
		this.html.querySelector('.panel_content').style.display = 'none';
	}

	open() {
		this.getLatestNews();
	}

	getLatestNews() {
		fetch(window.location.origin + '/api/news/getLatestArticles', {
			method: 'GET',
		}).then((response) => {
			response.json().then((jsonResponse) => {
				if (!jsonResponse) return;

				if (jsonResponse.success && jsonResponse.articles) {
					// this.setLatestNews(jsonResponse.articles);

					setTimeout(() => {
						this.setLatestNews(jsonResponse.articles);
					}, 500);
				}
			}).catch((error) => {
				console.log('failed to decode json');
				console.log(error);
			});
		});
	}

	setLatestNews(articles) {
		this.latestNews = articles;

		if (!this.newsButtons) {
			this.newsButtons = [];
		}

		const now = new Date().getTime() / 1000;
		const container = this.html.querySelector('.home_news_panel_buttons');

		for (let key in articles) {
			const articleData = articles[key];
			const secondsSince = articleData.postTime ? now - articleData.postTime : null;

			let timeSince;

			if (secondsSince >= 86_400) {
				timeSince = Math.floor(secondsSince / 86_400);// + ' days ago';
				timeSince += timeSince == 1 ? ' day ago' : ' days ago';
			} else if (secondsSince >= 3_600) {
				timeSince = Math.floor(secondsSince / 3_600);// + ' hours ago';
				timeSince += timeSince == 1 ? ' hour ago' : ' hours ago';
			} else if (secondsSince >= 60) {
				timeSince = Math.floor(secondsSince / 60);// + ' minutes ago';
				timeSince += timeSince == 1 ? ' minute ago' : ' minutes ago';
			} else if (secondsSince < 60) {
				timeSince = 'Just now';
			}

			let newsButton = this.newsButtons[key];

			if (!newsButton) {
				newsButton = createHTML(NewsButtonHTML)
				container.appendChild(newsButton);
				
				// newsButton.addEventListener('click', (event) => {
				// 	const articleData = this.latestNews && this.latestNews[key] || null;

				// 	if (articleData && articleData.id) {
				// 		navigateTo(`/news/${articleData.id}`);
				// 	} else {
				// 		console.warn(`Failed to open article, ID not found`);
				// 	}
				// })

				this.newsButtons[key] = newsButton;
			}

			newsButton.setAttribute('href', `/news/${articleData.id}`)
			newsButton.querySelector('.home_news_button_title').innerHTML = articleData.title || '?';
			newsButton.querySelector('.home_news_button_time').innerHTML = timeSince || '?';
		}

		this.newsSpinner.setParent();
		this.html.querySelector('.panel_content').style.display = null;
	}
}