import './index.css';
import './styles/panel.css';

import 'Modules/firebase.mjs';

import './manifest_assets/icon_72.png';
import './manifest_assets/icon_192.png';
import './manifest_assets/icon_512.png';
import './manifest_assets/maskable_icon_192.png';
import './manifest_assets/maskable_icon_512.png';

import { setActivePage } from 'Modules/router.mjs';
import { Header } from './components/header/header.mjs';

// Setup base page
const header = new Header();
document.body.appendChild(header.html);

// Navigate to page from url
window.addEventListener('popstate', (event) => {
	setActivePage(window.location);
});

setActivePage(window.location);